module.exports = ["苹果，水果之冠，色泽鲜艳，口感脆嫩多汁。它富含营养，既能满足味蕾，又能滋养身心。无论是直接食用，还是制作成果汁、甜点，都能展现其独特的魅力。苹果还象征着平安、健康，常被人们作为礼物赠送，寓意美好。在四季中，苹果总能带给我们无尽的惊喜与满足。",
 "香蕉，香甜可口，形状弯曲如月，皮薄肉嫩。它富含钾元素，能迅速补充能量，是运动员和劳累者的理想食品。香蕉口感软糯，老少皆宜，既可直接剥皮食用，也可制作甜品或混入果汁中。在热带地区，香蕉更是常见的水果，深受人们喜爱。",
 "桃子，色泽艳丽，果肉鲜美多汁，是夏季的佳果。它含有丰富的维生素和矿物质，不仅滋味甜美，更有滋补养身之效。桃子的形状犹如心形，寓意着爱情的甜蜜和美好。无论是鲜食还是制作成罐头、果酱，桃子都能展现出其独特的魅力，深受人们喜爱。",
 "西瓜，夏日之果，皮绿如翡翠，肉红似玛瑙。其味甜而多汁，清凉解暑，为夏季之佳品。每一口都仿佛带有自然的清香，甜而不腻，清爽宜人。西瓜不仅口感绝佳，且富含营养，是健康饮食的理想选择。在炎炎夏日，一片西瓜，便是清凉的享受。",
 "橙子，色泽鲜亮，皮薄肉厚，香甜可口。每一颗橙肉都饱含自然的甜蜜，清新而不失浓郁。它富含维生素C，既美味又健康，是水果中的佳品。在闲暇之余，剥一颗橙子，那香甜的汁液瞬间溢满口腔，让人回味无穷。",
 "牛油果，外形独特，表皮粗糙而肉质细腻。它富含不饱和脂肪酸，营养丰富，被誉为“森林奶油”。口感醇厚，略带坚果香气，既可直接食用，又可制作美食。无论是健康饮食还是味蕾享受，牛油果都是不错的选择。",
 "柠檬，色泽鲜亮，形如小果，酸爽可口。它富含维生素C，具有独特的清新香气，是调味佳品。无论是泡茶、烹饪还是制作饮品，柠檬都能为食物增添一抹清新的风味。它的酸味既提神醒脑，又开胃消食，是健康生活的良伴。",
 "火龙果，外形艳丽，果皮鲜红，内藏晶莹果肉。口感清甜，带有淡淡花香，是夏日消暑的佳品。它富含营养，既美味又健康，深受人们喜爱。无论是直接食用还是制作甜品，火龙果都能带来别样的味蕾享受。",
 "猕猴桃，外皮绒毛覆盖，翠绿诱人，肉质细嫩多汁，口感酸甜适中，富含丰富维生素C，是营养满满的水果佳品。其独特的风味和营养价值，让人一尝难忘，是健康生活的理想选择。",
 "芒果，色泽金黄，形似弯月，果肉饱满多汁，口感细腻甜美。它散发着独特的香气，令人垂涎欲滴。无论是直接食用还是制作甜品，芒果都能带来浓郁的热带风情，是夏日水果中的佼佼者。",
 "榴莲，外表坚硬带刺，内在却绵软香甜。其独特的浓郁香气，虽有人爱之如命，也有人避之不及。但一旦尝试，那绵密的口感和深邃的甜香，定会让人难以忘怀。榴莲不仅美味，还富含营养，是热带水果中的珍品。",
 "石榴，红艳艳的果皮内藏着颗颗晶莹的籽粒，宛如宝石般闪耀。口感酸甜多汁，清新爽口，是秋季的美味佳品。石榴不仅美味，还富含营养，寓意着多子多福，是节日庆典的吉祥果。",
 "山竹，外皮紫灰相间，厚实坚硬，内藏雪白嫩滑的果肉，清甜多汁，口感细腻，酸甜适中。被誉为“果中皇后”，营养丰富，尤其富含维生素，是热带水果中的珍品。品尝山竹，仿佛置身于热带雨林的清新之中，令人心旷神怡。",
 "草莓，色泽鲜艳，形如心形，果肉多汁，口感酸甜可口。它散发着迷人的香气，让人一闻便心生欢喜。草莓不仅美味，还富含维生素C，是健康水果的代表。每一颗草莓都如同小小的红宝石，为人们的味蕾带来无尽的享受。",
 "杨桃，外形独特，翠绿鹅黄，果肉清脆细腻，香甜可口。它富含蔗糖、果糖等多种营养素，滋味丰盈充沛，既可直接食用，又可用于烹饪调味。无论是口感还是营养价值，杨桃都堪称水果中的佳品，深受人们喜爱。",
 "梨，皮薄肉嫩，汁多味甜，清脆爽口。它富含维生素和矿物质，具有润肺止咳、清热解暑的功效。无论是直接食用还是炖煮成汤，梨都能展现出其独特的口感和营养价值，是秋季水果的佼佼者。",
 "菠萝，外形独特，表皮金黄，果肉酸甜可口，清香扑鼻。它富含维生素和矿物质，既可直接食用，又可制作各种美食。菠萝的鲜美口感和丰富营养让人欲罢不能，是热带水果中的佼佼者，深受人们喜爱。",
 "胡萝卜，色泽鲜艳，形如小棒，口感脆嫩，甜中带微辛。它富含胡萝卜素，营养丰富，被誉为“小人参”。生吃、熟食皆相宜，既可直接食用，又可烹饪成各种美味佳肴。胡萝卜，美味又健康，是餐桌上的常客。"
];